import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(150 - Math.random() * 50); // Reduced delay for typing speed
  const [index, setIndex] = useState(1);
  const toRotate = ["Software Engineer", "Backend Engineer", "Fullstack Engineer"];
  const period = 1000; // Reduced period for delay between words

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text, isDeleting, delta]); // Added dependencies to trigger effect on changes

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 1.5); // Speed up deletion
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period); // Speed up delay between words
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(150 - Math.random() * 50); // Reset delta to faster speed
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }
  const resumeLink = 'https://drive.google.com/file/d/1UfS1Nf28_538o7BBCTAcC-X-jtNxWKvN/view?usp=sharing';

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            {/* <TrackVisibility>
              {({ isVisible }) => */}
            {/* <div className={isVisible ? "animate__animated animate__fadeIn" : ""}> */}
            <div>
              <span className="tagline">Welcome to my Universe</span>
              <h2>{`Hi! I'm Tanzim, your reliable `} <span className="txt-rotate" dataPeriod="200"><span className="wrap">{text}</span></span></h2>
              <p>Unlock cutting-edge software solutions with me! I'm your go-to expert for REST API design and development, seamless OAuth 2.0 and Single Sign-On integration, optimized application speed and security, and efficient CI/CD pipelines. Proficient in React, I'm passionate about creating clean, efficient, and scalable solutions that make a difference. Let's connect and explore new opportunities together!</p>
              <a href={resumeLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <button >View my resume<ArrowRightCircle size={25} /></button>
              </a>
            </div>
            {/* }
            </TrackVisibility> */}
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img" />
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
